<template>
    <BT-Blade-Items
        addBladeName="customer-order"
        bladeName="customer-orders"
        canBreakdown
        canSearchLocal
        :canSelect="false"
        flexColumn
        :headers="[
            { text: 'Product', value: 'description', searchable: true, csv: true },
            { text: 'Quantity', value: 'quantity', searchable: true, breakdown: true, csvBreakdown: true }
        ]"
        :headersTwo="[
            { text: 'CO#', value: 'customerOrderNumber', subtitle: 2, prefix: 'CO#', searchable: true },
            { text: 'Created On', value: 'createdOn', textFilter: 'toDayMonth' },
            { text: 'Due Date', value: 'dueDate', textFilter: 'toDayMonth', subtitle: 1, prefix: 'Due: ' },
            { text: 'Customer', value: 'buyer.companyName', title: 1, searchable: true },
            { text: 'Destination', value: 'location', textFilter: 'toLocationLine', subtitle: 0, hide: true },
            { text: 'Total Value', value: 'amountTotal', textFilter: 'toCurrency' }]"
        hideActions
        hideActionsTwo
        :items="productItems"
        :itemsTwo="cOrders"
        :loadingMsg="loadingMsg"
        title="Customer Orders List">
        <template v-slot:actions>
            <BT-Select-Dialog
                canSearch
                hideButton
                navigation="journeys"
                returnObject
                :searchProps="['journeyName']"
                :showToggle="showJourneysToggle"
                title="Journeys"
                width="550"
                @change="addJourney">
                <template v-slot="{ item }">
                    <v-list-item-content>
                        <v-list-item-title>{{ item.journeyName }}</v-list-item-title>
                        <v-list-item-subtitle>
                            <span v-if="item.startedOn != null">Started</span>
                            <span v-else-if="!item.isReady">*Not Final Yet</span>
                            <span v-else>Ready</span>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action-text>{{ item.startedOn || item.dueStartOn | toDayShortDate }}</v-list-item-action-text>
                </template>
            </BT-Select-Dialog>

            <BT-Select-Dialog
                canSearch
                hideButton
                navigation="stock-consignments"
                :params="{ properties: 'ID,Buyer,DestinationLocation,DueDate,ConsignmentNumber' }"
                returnObject
                :searchProps="['buyer.companyName', 'consignmentNumber']"
                :showToggle="showConsignmentsToggle"
                title="Stock Consignments"
                width="550"
                @change="item => { addConsignment(item, 'consignment') }">
                <template v-slot="{ item }">
                    <v-list-item-content>
                        <v-list-item-title>{{ item.buyer.companyName }}</v-list-item-title>
                        <v-list-item-subtitle>{{ item.destinationLocation | toFamiliarLocationLine }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-list-item-action-text>{{ item.dueDate | toDayShortDate }}</v-list-item-action-text>
                        <v-list-item-action-text>CO# {{ item.consignmentNumber }}</v-list-item-action-text>
                    </v-list-item-action>
                </template>
            </BT-Select-Dialog>

            <BT-Select-Dialog
                canSearch
                hideButton
                navigation="stock-consignments"
                :params="{ filterBy: 'Transfers', properties: 'ID,Buyer,DestinationLocation,DueDate,ConsignmentNumber' }"
                returnObject
                :searchProps="['buyer.companyName', 'consignmentNumber']"
                :showToggle="showTransfersToggle"
                title="Transfers"
                width="550"
                @change="item => { addConsignment(item, 'transfer') }">
                <template v-slot="{ item }">
                    <v-list-item-content>
                        <v-list-item-title>{{ item.buyer.companyName }}</v-list-item-title>
                        <v-list-item-subtitle>{{ item.destinationLocation | toFamiliarLocationLine }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-list-item-action-text>{{ item.dueDate | toDayShortDate }}</v-list-item-action-text>
                        <v-list-item-action-text>CO# {{ item.consignmentNumber }}</v-list-item-action-text>
                    </v-list-item-action>
                </template>
            </BT-Select-Dialog>

            <BT-Select-Dialog
                canSearch
                hideButton
                navigation="releases"
                :params="{ filterBy: 'Pending,Bundled', properties: 'ID,DestinationLocation,DueArrivalOn,DueDepartureOn,BundleName' }"
                returnObject
                :searchProps="['destinationLocation.suburb','destinationLocation.companyAccount.companyName','destinationLocation.streetName','bundleName']"
                :showToggle="showBundlesToggle"
                title="Bundled Releases"
                width="550"
                @change="addRelease">
                <template v-slot="{ item }">
                    <v-list-item-content>
                        <v-list-item-title>{{ item.destinationLocation | toCompanyNameAndLocationLine }}</v-list-item-title>
                        <v-list-item-subtitle>{{ item.dueDepartureOn | toDayShortDate }} <v-spacer /> {{ item.bundleName }}</v-list-item-subtitle>
                    </v-list-item-content>
                </template>
            </BT-Select-Dialog>

            <BT-Menu
                icon="mdi-plus"
                isButton
                text="Add"
                title="Add Orders">
                <template v-slot>
                    <v-list-item @click="showConsignmentsToggle = !showConsignmentsToggle">
                        <v-list-item-icon><v-icon>mdi-clipboard</v-icon></v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Open Consignment</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="showJourneysToggle = !showJourneysToggle">
                        <v-list-item-icon><v-icon>mdi-truck</v-icon></v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Open Journey</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="showBundlesToggle = !showBundlesToggle">
                        <v-list-item-icon><v-icon>mdi-clipboard-play-multiple</v-icon></v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Open Bundled Release</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="showTransfersToggle = !showTransfersToggle">
                        <v-list-item-icon><v-icon>mdi-swap-horizontal</v-icon></v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Open Transfer</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </BT-Menu>

            <v-btn @click="showCustomerOrders = !showCustomerOrders">
                {{ showCustomerOrders ? 'Hide ' : 'Show ' }}Customer Orders
            </v-btn>
        </template>
        <!-- <template v-slot:itemActionsTwo="{ item, items }">
            <v-btn icon small @click="remove(item, items)">
                <v-icon class="error--text" small>mdi-delete</v-icon>
            </v-btn>
        </template> -->
    </BT-Blade-Items>
</template>

<script>
import { firstBy } from 'thenby';

export default {
    name: 'Customer-Orders-List-Blade',
    components: {
        BTMenu: () => import('~components/BT-Menu.vue'),
        BTSelectDialog: () => import('~components/BT-Select-Dialog.vue'),
    },
    data: function() {
        return {
            customerOrders: [],
            loadingMsg: null,
            msg: null,
            productItems: [], //{ product, qty }

            showBundlesToggle: false,
            showJourneysToggle: false,
            showConsignmentsToggle: false,
            showTransfersToggle: false,

            showCustomerOrders: false,
        }
    },
    computed: {
        cOrders() {
            return this.showCustomerOrders ? this.customerOrders : null;
        }
    },
    methods: {
        addCustomerOrder(co) {
            this.customerOrders.push(co);

            co.orderItems.forEach(oItem => {
                var p = this.productItems.find(z => z.productID == oItem.productID);
                if (p == null) {
                    p = oItem;
                    this.productItems.push(p);
                }
                else {
                    p.quantity += oItem.quantity;
                }
            })

            this.productItems.sort(firstBy(x => x.sortNumber));
        },
        async addConsignment(c) {
            await this.pullConsignments([c.id]);
        },
        async addJourney(item) {
            var consignmentIDsRes = await this.$BlitzIt.api.get('journeys', '/GetConsignmentIDs/GetConsignmentIDs/' + item.id, null, null);
            await this.pullConsignments(consignmentIDsRes.data.data);
        },
        async addRelease(item) {
            var consignmentIDsRes = await this.$BlitzIt.api.get('releases', '/GetConsignmentIDs/GetConsignmentIDs/' + item.id, null, null);
            await this.pullConsignments(consignmentIDsRes.data.data);
        },
        async pullConsignments(consIDs) {
            var filteredIDs = consIDs.filter(id => !this.customerOrders.some(co => id == co.id));
            var excessIDs = filteredIDs;
            var iter = 0;

            if (this.isLengthyArray(excessIDs)) {
                try {
                    if (this.isLengthyArray(excessIDs)) {
                    //pull in sets of 20
                    do {
                        var conIDs = excessIDs.slice(0, excessIDs.length > 20 ? 20 : excessIDs.length);
                        iter += conIDs.length;
                        this.loadingMsg = `${iter} of ${filteredIDs.length} customer orders`;
                        var cResults = this.copyDeep(await this.$BlitzIt.store.getAll('customer-orders', { ids: conIDs.toString(), includeDetails: true }, true));
                        
                        cResults.forEach(cOrder => {
                            this.addCustomerOrder(cOrder);
                        })

                        excessIDs = excessIDs.filter(x => !conIDs.some(y => y == x));
                    } while (this.isLengthyArray(excessIDs))
                    }
                }
                catch (err) {
                    this.msg = this.extractErrorDescription(err);
                }
                finally {
                    this.loadingMsg = null;
                }
            }
        },
        remove(co, coList) {
            var ind = coList.findIndex(x => x.id == co.id);
            if (ind >= 0) {
                coList.splice(ind, 1);
            }
        }
    }
}
</script>