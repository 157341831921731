var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BT-Blade-Items',{attrs:{"addBladeName":"customer-order","bladeName":"customer-orders","canBreakdown":"","canSearchLocal":"","canSelect":false,"flexColumn":"","headers":[
        { text: 'Product', value: 'description', searchable: true, csv: true },
        { text: 'Quantity', value: 'quantity', searchable: true, breakdown: true, csvBreakdown: true }
    ],"headersTwo":[
        { text: 'CO#', value: 'customerOrderNumber', subtitle: 2, prefix: 'CO#', searchable: true },
        { text: 'Created On', value: 'createdOn', textFilter: 'toDayMonth' },
        { text: 'Due Date', value: 'dueDate', textFilter: 'toDayMonth', subtitle: 1, prefix: 'Due: ' },
        { text: 'Customer', value: 'buyer.companyName', title: 1, searchable: true },
        { text: 'Destination', value: 'location', textFilter: 'toLocationLine', subtitle: 0, hide: true },
        { text: 'Total Value', value: 'amountTotal', textFilter: 'toCurrency' }],"hideActions":"","hideActionsTwo":"","items":_vm.productItems,"itemsTwo":_vm.cOrders,"loadingMsg":_vm.loadingMsg,"title":"Customer Orders List"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('BT-Select-Dialog',{attrs:{"canSearch":"","hideButton":"","navigation":"journeys","returnObject":"","searchProps":['journeyName'],"showToggle":_vm.showJourneysToggle,"title":"Journeys","width":"550"},on:{"change":_vm.addJourney},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.journeyName))]),_c('v-list-item-subtitle',[(item.startedOn != null)?_c('span',[_vm._v("Started")]):(!item.isReady)?_c('span',[_vm._v("*Not Final Yet")]):_c('span',[_vm._v("Ready")])])],1),_c('v-list-item-action-text',[_vm._v(_vm._s(_vm._f("toDayShortDate")(item.startedOn || item.dueStartOn)))])]}}])}),_c('BT-Select-Dialog',{attrs:{"canSearch":"","hideButton":"","navigation":"stock-consignments","params":{ properties: 'ID,Buyer,DestinationLocation,DueDate,ConsignmentNumber' },"returnObject":"","searchProps":['buyer.companyName', 'consignmentNumber'],"showToggle":_vm.showConsignmentsToggle,"title":"Stock Consignments","width":"550"},on:{"change":function (item) { _vm.addConsignment(item, 'consignment') }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.buyer.companyName))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm._f("toFamiliarLocationLine")(item.destinationLocation)))])],1),_c('v-list-item-action',[_c('v-list-item-action-text',[_vm._v(_vm._s(_vm._f("toDayShortDate")(item.dueDate)))]),_c('v-list-item-action-text',[_vm._v("CO# "+_vm._s(item.consignmentNumber))])],1)]}}])}),_c('BT-Select-Dialog',{attrs:{"canSearch":"","hideButton":"","navigation":"stock-consignments","params":{ filterBy: 'Transfers', properties: 'ID,Buyer,DestinationLocation,DueDate,ConsignmentNumber' },"returnObject":"","searchProps":['buyer.companyName', 'consignmentNumber'],"showToggle":_vm.showTransfersToggle,"title":"Transfers","width":"550"},on:{"change":function (item) { _vm.addConsignment(item, 'transfer') }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.buyer.companyName))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm._f("toFamiliarLocationLine")(item.destinationLocation)))])],1),_c('v-list-item-action',[_c('v-list-item-action-text',[_vm._v(_vm._s(_vm._f("toDayShortDate")(item.dueDate)))]),_c('v-list-item-action-text',[_vm._v("CO# "+_vm._s(item.consignmentNumber))])],1)]}}])}),_c('BT-Select-Dialog',{attrs:{"canSearch":"","hideButton":"","navigation":"releases","params":{ filterBy: 'Pending,Bundled', properties: 'ID,DestinationLocation,DueArrivalOn,DueDepartureOn,BundleName' },"returnObject":"","searchProps":['destinationLocation.suburb','destinationLocation.companyAccount.companyName','destinationLocation.streetName','bundleName'],"showToggle":_vm.showBundlesToggle,"title":"Bundled Releases","width":"550"},on:{"change":_vm.addRelease},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("toCompanyNameAndLocationLine")(item.destinationLocation)))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm._f("toDayShortDate")(item.dueDepartureOn))+" "),_c('v-spacer'),_vm._v(" "+_vm._s(item.bundleName))],1)],1)]}}])}),_c('BT-Menu',{attrs:{"icon":"mdi-plus","isButton":"","text":"Add","title":"Add Orders"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-list-item',{on:{"click":function($event){_vm.showConsignmentsToggle = !_vm.showConsignmentsToggle}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-clipboard")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Open Consignment")])],1)],1),_c('v-list-item',{on:{"click":function($event){_vm.showJourneysToggle = !_vm.showJourneysToggle}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-truck")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Open Journey")])],1)],1),_c('v-list-item',{on:{"click":function($event){_vm.showBundlesToggle = !_vm.showBundlesToggle}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-clipboard-play-multiple")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Open Bundled Release")])],1)],1),_c('v-list-item',{on:{"click":function($event){_vm.showTransfersToggle = !_vm.showTransfersToggle}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-swap-horizontal")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Open Transfer")])],1)],1)]},proxy:true}])}),_c('v-btn',{on:{"click":function($event){_vm.showCustomerOrders = !_vm.showCustomerOrders}}},[_vm._v(" "+_vm._s(_vm.showCustomerOrders ? 'Hide ' : 'Show ')+"Customer Orders ")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }